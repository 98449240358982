const faqData = [
    {
        question: "Скільки часу займає навчання?",
        answer: "Уроки - об'ємні і потребують часу на опрацювання. Це не історія про 15 хв в день, будьте готові приділяти  час та сили навчанню, в тому числі і комунікації з куратором та виконанню завдань. Всі уроки - записані професійно та знаходяться на зручній платформі. Ви переглядаєте в час, що є для Вас комфортним. Зум-зустрічі - за попереднім графіком, проте, у випадку якщо не вийде - є змога переглянути в записі."
    },

    {
        question: "Чи можна розбити вартість навчання на кілька платежів?",
        answer: "Так, обирайте на сайті оплату частинами до 12 місяців."
    },

    {
        question: "У мене залишиться доступ до матеріалів?",
        answer: "Доступ до матеріалів ми надаємо на 6 місяців. Тому після навчання за потреби буде змога повернутись та повторно переглянути потрібні уроки. Також ми спілкуємось з ученицями та підтримаємо навіть роки після завершення курсу."
    },

    {
        question: "Ми розбиратемемо реальні весілля?",
        answer: "Так - це одна з улюблених частин навчання. В нас багато кейсів та прикладів саме з життя Mopis. Будуть також окремі комплексні розбори концепцій весіль."
    },

    {
        question: "У мене зовсім немає досвіду в декорі. Чи підійде мені навчання?",
        answer: "Це найбільш повне на комплексне навчання на ринку. Ідеальна база, щоб одразу розпочати все правильно - з баченням як має працювати організатор, знаннями кожного етапу, необхідною документацією та підтримкою."
    },

    {
        question: "Я досвідчений декоратор. Що мені може дати цей курс?",
        answer: "Прорив в новому весільному сезоні. Систематизацію знань, новий погляд на певні процеси в роботі. Спілкування та інсайти. Попри те, що певні теми можуть бути знайомі - нової інформації буде достатньо. Особливо актуальним буде маркетинговий блок, та додаткові лекції які цього потоку додали саме для дівчат з досвідом."
    },
];

export default faqData;